import React from "react";
import SwiperCore, { Navigation } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper-bundle.min.css";
import "swiper/swiper.min.css";
import "./SpecialSwiper.scss";
import fruta1 from "../../assets/temp/fruta-1.jpg";
import fruta2 from "../../assets/temp/fruta-2.jpg";
import fruta3 from "../../assets/temp/fruta-3.jpg";
import fruta4 from "../../assets/temp/fruta-4.jpg";

SwiperCore.use([Navigation]);

export const SpecialSwiper = () => {
  return (
    <section className="section section--products-special">
      <p className="products-special__title">Productos Especiales</p>
      <Swiper
        navigation={true}
        loop={true}
        centeredSlides={false}
        slidesPerView={1}
        spaceBetween={30}
        className="swiper-special-products"
        breakpoints={{
          640: {
            slidesPerView: 2,
            spaceBetween: 20,
          },
          768: {
            slidesPerView: 3,
            spaceBetween: 40,
          },
          1024: {
            slidesPerView: 4,
            spaceBetween: 40,
          },
        }}
      >
        <SwiperSlide>
          <div className="products-special__card">
            <div className="products-special__card--top">
              <img src={fruta1} alt="" />
            </div>
            <div className="products-special__card--bottom">
              <p className="name">Primera Fruta</p>
              <p className="price">$1111</p>
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="products-special__card">
            <div className="products-special__card--top">
              <img src={fruta3} alt="" />
            </div>
            <div className="products-special__card--bottom">
              <p className="name">Nombre Fruta</p>
              <p className="price">$1111</p>
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="products-special__card">
            <div className="products-special__card--top">
              <img src={fruta2} alt="" />
            </div>
            <div className="products-special__card--bottom">
              <p className="name">Nombre Fruta</p>
              <p className="price">$1111</p>
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="products-special__card">
            <div className="products-special__card--top">
              <img src={fruta3} alt="" />
            </div>
            <div className="products-special__card--bottom">
              <p className="name">Nombre Fruta</p>
              <p className="price">$1111</p>
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="products-special__card">
            <div className="products-special__card--top">
              <img src={fruta4} alt="" />
            </div>
            <div className="products-special__card--bottom">
              <p className="name">Nombre Fruta</p>
              <p className="price">$1111</p>
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="products-top__card">
            <div className="products-top__card--top">
              <img src={fruta1} alt="" />
            </div>
            <div className="products-top__card--bottom">
              <p className="name">Última Fruta</p>
              <p className="price">$1111</p>
            </div>
          </div>
        </SwiperSlide>
      </Swiper>
    </section>
  );
};
