import React, { useState, useEffect } from 'react';
import { Modal, Box, Typography, TextField, Button, MenuItem } from '@mui/material';
import { getCities } from '../../client/apiCities'; // API para obtener comunas
import { getRegions } from '../../client/apiRegions'; // API para obtener regiones

export const EditAddressModal = ({ open, handleClose, address, onSave }) => {
  const [newAddress, setNewAddress] = useState(address.address);
  const [selectedCity, setSelectedCity] = useState(address.city);
  const [selectedRegion, setSelectedRegion] = useState(address.region);
  const [regions, setRegions] = useState([]);
  const [cities, setCities] = useState([]);
  const [filteredCities, setFilteredCities] = useState([]);

  /*useEffect(() => {
    setNewAddress(address.address);
    setSelectedCity(address.city);
    setSelectedRegion(address.region);

    // Cargar regiones al montar el componente
    fetchRegions();
  }, [address]);*/

  useEffect(() => {
    // Cargar comunas cuando la región seleccionada cambia
    //if (selectedRegion) {
      fetchCities();
      fetchRegions();
    //}
  }, []);

  const fetchRegions = async () => {
    try {
      const regionsData = await getRegions(); // Llamar a la API para obtener regiones
      setRegions(regionsData);
    } catch (error) {
      console.error('Error fetching regions:', error);
    }
  };

  const fetchCities = async () => {
    try {
      const citiesData = await getCities(); // Llamar a la API para obtener comunas de la región
      setFilteredCities(citiesData);
    } catch (error) {
      console.error('Error fetching cities:', error);
    }
  };

  const handleSave = () => {
    onSave({ address: newAddress, city: selectedCity, region: selectedRegion });
    handleClose();
  };

  return (
    <Modal open={open} onClose={handleClose}>
      <Box sx={{ width: 400, margin: '100px auto', padding: 4, backgroundColor: 'white', borderRadius: 2 }}>
        <Typography variant="h6" gutterBottom>
          Editar Dirección
        </Typography>
        <TextField
          label="Nueva Dirección"
          value={newAddress}
          onChange={(e) => setNewAddress(e.target.value)}
          fullWidth
          sx={{ mb: 2 }}
        />
        <TextField
          label="Región"
          select
          value={selectedRegion}
          onChange={(e) => setSelectedRegion(e.target.value)}
          fullWidth
          sx={{ mb: 2 }}
        >
          {regions.map((region) => (
            <MenuItem key={region.id} value={region.name}>
              {region.name}
            </MenuItem>
          ))}
        </TextField>
        <TextField
          label="Comuna"
          select
          value={selectedCity}
          onChange={(e) => setSelectedCity(e.target.value)}
          fullWidth
          sx={{ mb: 2 }}
          disabled={!selectedRegion} // Deshabilitar hasta que se seleccione una región
        >
          {filteredCities.map((city) => (
            <MenuItem key={city.id} value={city.name}>
              {city.name}
            </MenuItem>
          ))}
        </TextField>
        <Button variant="contained" color="primary" onClick={handleSave}>
          Guardar
        </Button>
        <Button variant="text" onClick={handleClose} sx={{ ml: 2 }}>
          Cancelar
        </Button>
      </Box>
    </Modal>
  );
};
