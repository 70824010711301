import axios from 'axios';
import { API_URL } from '../const/api';

const API = `${API_URL}/memberships`; // Cambia la URL según tu configuración
export const getMembershipsTypes = async () => {
  try {
    const response = await axios.get(`${ API }/types`, {
      headers: {
        'Content-Type': 'application/json',
      },
    });
    return response.data;
  } catch (error) {
    console.error('Error en la solicitud:', error);
    throw error;
  }
};

export const getMembershipsByClient = async (id) => {
  try {
    const response = await axios.get(`${ API }/client/${id}`, {
      headers: {
        'Content-Type': 'application/json',
      },
    });
    return response.data;
  } catch (error) {
    console.error('Error en la solicitud:', error);
    throw error;
  }
};