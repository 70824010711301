import React from "react";
import { Link } from "react-router-dom";
import "./Footer.scss";
import facebook from "../../assets/icon/facebook.svg";
import instagram from "../../assets/icon/instagram.svg";
import youtube from "../../assets/icon/youtube.svg";
import plane from "../../assets/icon/plane.svg";
import logoMovil from "../../assets/temp/takay-movil.png";
import {  useMediaQuery } from "@mui/material";
import logo from "../../assets/temp/logo-header-takay.png";

export const Footer = () => {
  const isMobile = useMediaQuery("(max-width: 1024px)");
  return (
    <footer className="footer">
      <div className="footer-container">
        <div className="footer__col footer__col--info">
          <div className="col-wrap">
          <Link to="/" style={{ textDecoration: "none" }}>
            <img
              src={isMobile ? logoMovil : logo}
              alt="T'akay Logo"
              style={{ height: isMobile ? 40 : 60 }}
            />
          </Link>
            <ul className="footer__rrss">
              <li className="footer__rrsslink">
                <a href="http://" target="_blank" rel="noopener noreferrer">
                  <img src={facebook} alt="Facebook" />
                </a>
              </li>
              <li className="footer__rrsslink">
                <a href="http://" target="_blank" rel="noopener noreferrer">
                  <img src={instagram} alt="Instagram" />
                </a>
              </li>
              <li className="footer__rrsslink">
                <a href="http://" target="_blank" rel="noopener noreferrer">
                  <img src={youtube} alt="Youtube" />
                </a>
              </li>
            </ul>
            <a
              className="tyc"
              href="http://"
              target="_blank"
              rel="noopener noreferrer"
            >
              Politicas de privacidad
            </a>
            <a
              className="tyc"
              href="http://"
              target="_blank"
              rel="noopener noreferrer"
            >
              Terminos y condiciones
            </a>
          </div>
        </div>
        <div className="footer__col footer__col--contact">
          <div className="col-wrap">
            <p className="footer__title">Contacto</p>
            <div className="footer__contact">
              <a href="tel:+">+56973765050</a>
              <a href="mailto:">contacto@takayapp.cl</a>
              <address>Santiago, Chile</address>
            </div>
          </div>
        </div>
        <div className="footer__col footer__col--newsletter">
          <div className="col-wrap">
            <p className="footer__title">Newsletter</p>
            <p className="footer__description">
              Suscribete para recibir novedades y ofertas especiales.
            </p>
            <form>
              <fieldset>
                <input type="text" placeholder="Ingresa tu correo" />
                <button type="submit">
                  <img src={plane} alt="" />
                </button>
              </fieldset>
            </form>
          </div>
        </div>
      </div>
    </footer>
  );
};
