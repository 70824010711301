import React from 'react';
import { Box, Typography, Card, CardContent, CardActions, Chip, Grid, Avatar } from '@mui/material';
import { MonetizationOn, CheckCircle, Cancel } from '@mui/icons-material';
import { green, red } from '@mui/material/colors';

const MembershipHistory = ({ memberships }) => {
  // Ordenar las membresías por fecha de creación en orden descendente
  const sortedMemberships = memberships.sort(
    (a, b) => new Date(b.created_at) - new Date(a.created_at)
  );

  return (
    <Box sx={{ width: '100%', maxWidth: 800, mx: 'auto', p: 2 }}>
      <Typography variant="h5" gutterBottom sx={{ textAlign: 'center', mb: 4 }}>
        Historial de Membresías
      </Typography>
      <Grid container spacing={2}>
        {sortedMemberships.map((membership) => (
          <Grid item xs={12} key={membership.id}>
            <Card
              sx={{
                borderRadius: 2,
                boxShadow: 3,
                border: '1px solid',
                borderColor: membership.status.name === 'Active' ? green[300] : red[300],
                backgroundColor: membership.status.name === 'Active' ? green[50] : red[50],
                minHeight: '120px', // Reducir la altura mínima
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
              }}
            >
              <CardContent sx={{ paddingBottom: '8px' }}>
                <Box display="flex" justifyContent="space-between" alignItems="center" mb={1}>
                  <Box display="flex" alignItems="center">
                    <Typography variant="h6" component="div" sx={{ fontWeight: 'bold', mr: 2 }}>
                      {membership.type.name}
                    </Typography>
                    <Typography variant="body2" color="textSecondary">
                      {membership.type.description}
                    </Typography>
                  </Box>
                  <Chip
                    icon={
                      membership.status.name === 'Active' ? (
                        <CheckCircle style={{ color: green[500] }} />
                      ) : (
                        <Cancel style={{ color: red[500] }} />
                      )
                    }
                    label={membership.status.name === 'Active' ? 'Activa' : 'Inactiva'}
                    style={{
                      backgroundColor: membership.status.name === 'Active' ? green[100] : red[100],
                      color: membership.status.name === 'Active' ? green[800] : red[800],
                    }}
                  />
                </Box>

                <Box display="flex" justifyContent="space-between" alignItems="center">
                  <Typography variant="body2" color="textSecondary">
                    <strong>Expira:</strong> {new Date(membership.expired_at).toLocaleDateString()}
                  </Typography>
                  <Typography variant="body2" color="textSecondary">
                    <strong>Creada:</strong> {new Date(membership.created_at).toLocaleDateString()}
                  </Typography>
                </Box>
              </CardContent>

              <CardActions sx={{ paddingTop: 1, paddingLeft: 2, paddingRight: 2 }}>
                <Box display="flex" alignItems="center">
                  <Avatar sx={{ bgcolor: green[500], mr: 1 }}>
                    <MonetizationOn />
                  </Avatar>
                  <Typography variant="h6" sx={{ color: green[800], fontWeight: 'bold' }}>
                    Saldo: ${membership.balance.toLocaleString('es-CL')}
                  </Typography>
                </Box>
              </CardActions>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default MembershipHistory;
