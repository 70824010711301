import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./styles/styles.scss";
import { Header } from "./components/Header/Header";
import { Footer } from "./components/Footer/Footer";
import { Home } from "./pages/Home/Home";
import { CartPage } from "./components/CartPage/CartPage";
import { SubscriptionPage } from "./components/Suscription/Suscription";
import { RegisterForm } from "./components/Register/Register";
import { Account } from "./components/Account/Account";
import { AuthProvider } from "./context/AuthContext";
import { PaymentSuccessPage } from "./components/PaymentSuccessPage/PaymentSuccessPage"; 
import { SalesSuccessPage } from "./components/PaymentSuccessPage/SalesSuccess"; 
import { TransferPaymentInfo } from "./components/PaymentSuccessPage/TransferPaymentInfoPage"; 
//import Auth from "./components/Auth";
function App() {
  console.log('init app v1');
  return (
    
      <Router>
        <AuthProvider>
        <Header />
       
        <main>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/carrito" element={<CartPage />} />
            <Route path="/suscripcion" element={<SubscriptionPage />} />
            <Route path="/register" element={<RegisterForm />} />
            <Route path="/account" element={<Account />} />
            <Route path="/payment-success" element={<PaymentSuccessPage />} />
            <Route path="/sales-success" element={<SalesSuccessPage />} />
            <Route path="/transfer-payment" element={<TransferPaymentInfo />} />
          </Routes>
        </main>
        <Footer />
        <ToastContainer />
        </AuthProvider>
      </Router>
  );
}

export default App;
