import React, { useState, useEffect } from 'react';
import { Box, Menu, MenuItem, Typography } from '@mui/material';
import PersonIcon from '@mui/icons-material/Person';
import { useNavigate } from 'react-router-dom';

const UserProfileMenu = ({ authenticatedUser, onLogout }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const navigate = useNavigate(); // Hook de navegación

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleNavigateToAccount = () => {
    handleMenuClose();
    navigate('/account'); // Redirigir a la página de cuenta
  };

  const handleLogoutAndClose = () => {
    handleMenuClose();
    onLogout(); // Llamar a la función de cierre de sesión
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (anchorEl && !anchorEl.contains(event.target)) {
        handleMenuClose();
      }
    };

    document.addEventListener('click', handleClickOutside);
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, [anchorEl]);

  return (
    <Box
      display="flex"
      alignItems="center"
      sx={{
        backgroundColor: "#333",
        color: "white",
        padding: "8px 16px",
        borderRadius: 2,
        cursor: 'pointer'
      }}
      onClick={handleMenuOpen}
    >
      <PersonIcon sx={{ marginRight: 1 }} />
      <Typography>
        ¡Hola, {authenticatedUser}!
      </Typography>

      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        MenuListProps={{
          sx: {
            padding: 0, // Quitar el padding alrededor de la lista
          },
        }}
      >
        <MenuItem
          onClick={handleNavigateToAccount}
          sx={{
            padding: '4px 16px', // Ajustar el padding de cada elemento
            minHeight: 'auto', // Eliminar el mínimo de altura predeterminado
          }}
        >
          Mi cuenta
        </MenuItem>
        <MenuItem
          onClick={handleLogoutAndClose}
          sx={{
            padding: '4px 16px', // Ajustar el padding de cada elemento
            minHeight: 'auto', // Eliminar el mínimo de altura predeterminado
          }}
        >
          Cerrar sesión
        </MenuItem>
      </Menu>
    </Box>
  );
};

export default UserProfileMenu;
