import React, { useState, useEffect, useContext } from "react";
import { Modal, Box, Typography, Card, CardContent, CardActions, Button, useMediaQuery } from "@mui/material";
import { AuthContext } from "../../context/AuthContext";
import Auth from "./../Auth/Auth";
import { getMembershipsTypes } from "../../client/apiMemberships";
import { createTransactionMembership } from "../../client/apiWebpayService";

const MembershipModal = ({ open, onClose }) => {
  const { user, userId } = useContext(AuthContext);
  const [authOpen, setAuthOpen] = useState(false);
  const [memberships, setMemberships] = useState([]);
  
  // Media query para pantallas pequeñas
  const isMobile = useMediaQuery("(max-width: 600px)");

  useEffect(() => {
    const fetchMemberships = async () => {
      try {
        const data = await getMembershipsTypes();
        setMemberships(data);
      } catch (error) {
        console.error('Error al cargar las membresías:', error);
      }
    };

    if (open) {
      fetchMemberships();
    }
  }, [open]);

  const calculateCartTotalAndSave = () => {
    const cartItems = JSON.parse(localStorage.getItem("cartItems")) || [];

    const total = cartItems.reduce((sum, item) => {
      if (item.isMembership) {
        return sum + item.price_membership * item.quantity;
      }
      return sum;
    }, 0);

    localStorage.setItem("tempCartTotal", total);

    return total;
  };

  const handleCheckout = async (amount, membershipId) => {
    if (!user) {
      setAuthOpen(true);
      return;
    }

    calculateCartTotalAndSave();

    try {
      localStorage.setItem("membershipId", membershipId);
      localStorage.setItem("userId", userId);
      const response = await createTransactionMembership(amount);
      if (response.url && response.token) {
        window.location.href = `${response.url}?token_ws=${response.token}`;
      }
    } catch (error) {
      console.error("Error al iniciar la transacción:", error);
    }
  };

  return (
    <>
      <Modal open={open} onClose={onClose} aria-labelledby="modal-title">
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: isMobile ? '90%' : 600, // Ajustar el ancho en pantallas pequeñas
            maxWidth: '100%',
            bgcolor: "background.paper",
            borderRadius: 2,
            boxShadow: 24,
            p: 4,
            display: 'flex',
            flexDirection: 'column',
            gap: 2,
            overflowY: "auto", // Agregar scroll si el contenido excede el alto del modal
            maxHeight: "90vh", // Limitar el alto máximo en pantallas pequeñas
          }}
        >
          <Typography
            id="modal-title"
            variant="h5"
            component="h2"
            sx={{
              mb: 2,
              textAlign: "center",
              fontSize: isMobile ? "1.25rem" : "1.5rem", // Ajustar el tamaño del texto
            }}
          >
            Selecciona tu Membresía
          </Typography>

          <Box
            sx={{
              display: "flex",
              flexDirection: isMobile ? "column" : "row", // Cambiar a columna en pantallas pequeñas
              gap: 2,
              overflowX: isMobile ? "hidden" : "auto", // Solo permitir scroll horizontal en pantallas grandes
            }}
          >
            {memberships.map((membership) => (
              <Card
                key={membership.id}
                variant="outlined"
                sx={{
                  width: isMobile ? "100%" : 180, // Ajustar el ancho en pantallas pequeñas
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                }}
              >
                <CardContent>
                  <Typography variant="h6" component="h3" sx={{ fontSize: isMobile ? "1rem" : "1.2rem" }}>
                    {membership.name}
                  </Typography>
                  <Typography
                    variant="body2"
                    color="text.secondary"
                    sx={{ mb: 2, fontSize: isMobile ? "0.9rem" : "1rem" }}
                  >
                    ${Number(membership.amount).toLocaleString('es-CL')}
                  </Typography>
                  <Typography variant="body2" sx={{ fontSize: isMobile ? "0.8rem" : "1rem" }}>
                    {membership.description}
                  </Typography>
                </CardContent>
                <CardActions sx={{ mt: "auto" }}>
                  <Button
                    variant="contained"
                    sx={{
                      bgcolor: "green",
                      color: "white",
                      fontSize: isMobile ? "0.8rem" : "1rem", // Ajustar tamaño del botón
                      '&:hover': { bgcolor: 'darkgreen' }
                    }}
                    fullWidth
                    onClick={() => handleCheckout(Number(membership.amount), membership.id)}
                  >
                    Pagar
                  </Button>
                </CardActions>
              </Card>
            ))}
          </Box>
        </Box>
      </Modal>

      <Auth open={authOpen} onClose={() => setAuthOpen(false)} />
    </>
  );
};

export default MembershipModal;
