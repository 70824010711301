import React, { useState, useContext, useEffect } from "react";
import {
  SwipeableDrawer,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  IconButton,
  Typography,
  Button,
  Box,
  Avatar,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  useMediaQuery,
} from "@mui/material";
import { Add, Remove, Delete, ShoppingCart } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { CartContext } from "../../context/CartContext";
import { AuthContext } from "../../context/AuthContext";

const CartDrawer = ({ open, onClose, onOpen = () => {} }) => {
  const { cartItems, updateQuantity, removeFromCart, clearCart, addToCart, totalPrice, totalSavings } = useContext(CartContext);
  const { balance, setBalance } = useContext(AuthContext);
  const [openDialog, setOpenDialog] = useState(false);
  const [hasShownLimitMessage, setHasShownLimitMessage] = useState(false);
  const navigate = useNavigate();
  const isMobile = useMediaQuery("(max-width: 600px)");

  const totalQuantity = cartItems.reduce((total, item) => total + item.quantity, 0);

  const formatPrice = (price) => {
    return new Intl.NumberFormat("es-CL", { style: "currency", currency: "CLP" }).format(price);
  };

  const calculateTotalSavings = () => {
    return cartItems
      .filter(item => item.isMembership) // Filtrar solo productos con membresía
      .reduce((total, item) => {
        const normalPrice = parseFloat(item.price_normal || item.price); // Precio normal
        const membershipPrice = parseFloat(item.price_membership); // Precio de membresía
        const itemSavings = (normalPrice - membershipPrice) * item.quantity; // Ahorro por producto
        return total + itemSavings;
      }, 0);
  };

  useEffect(() => {
    // Aquí puedes actualizar el estado del ahorro total si lo deseas
  }, [cartItems]);

  const handleIncrease = (product) => {
    const productPriceMembership = parseFloat(product.price_membership);
    const productPriceNormal = parseFloat(product.price);
    const currentQuantity = getProductQuantity(product.id);

    if (product.isMembership) {
      if (balance >= productPriceMembership) {
        updateQuantity(product.id, currentQuantity + 1);
        setBalance(balance - productPriceMembership);
      } else {
        if (!hasShownLimitMessage) {
          setOpenDialog(true);
          setHasShownLimitMessage(true);
        }

        const productNormalId = `${product.id}_normal`;
        const productInCartNormal = cartItems.find((item) => item.id === productNormalId);

        if (productInCartNormal) {
          updateQuantity(productNormalId, productInCartNormal.quantity + 1);
        } else {
          const productWithNormalPrice = {
            ...product,
            id: productNormalId,
            isMembership: false,
            price: productPriceNormal,
          };
          addToCart(productWithNormalPrice, 1);
        }
      }
    } else {
      updateQuantity(product.id, currentQuantity + 1);
    }
  };

  const handleDecrease = (product) => {
    const productInCart = cartItems.find((item) => item.id === product.id && item.isMembership === product.isMembership);

    if (productInCart) {
      if (productInCart.quantity > 1) {
        updateQuantity(product.id, productInCart.quantity - 1);
        if (productInCart.isMembership) {
          setBalance((prevBalance) => prevBalance + parseFloat(product.price_membership));
        }
      } else if (productInCart.quantity === 1) {
        removeProductAndUpdateBalance(product);
      }
    }
  };

  const removeProductAndUpdateBalance = (product) => {
    const productInCart = cartItems.find((item) => item.id === product.id && item.isMembership === product.isMembership);

    if (productInCart) {
      removeFromCart(product.id);
      if (productInCart.isMembership) {
        const totalRefund = productInCart.quantity * parseFloat(product.price_membership);
        setBalance((prevBalance) => prevBalance + totalRefund);
      }
    }
  };

  const getProductQuantity = (productId) => {
    const product = cartItems.find((item) => item.id === productId);
    return product ? product.quantity : 0;
  };

  const handleDialogClose = () => {
    setOpenDialog(false);
  };

  const handleGoToCart = () => {
    onClose();
    navigate("/carrito");
  };

  const handleClearCart = () => {
    const totalRefund = cartItems
      .filter((item) => item.isMembership)
      .reduce((total, item) => total + (parseFloat(item.price_membership) * item.quantity), 0);

    setBalance((prevBalance) => prevBalance + totalRefund);
    clearCart();
    setHasShownLimitMessage(false);
  };

  return (
    <SwipeableDrawer
    anchor={isMobile ? "bottom" : "right"}
    open={open}
    onClose={onClose}
    onOpen={onOpen}
    disableSwipeToOpen={false}
    sx={{
      "& .MuiDrawer-paper": {
        width: isMobile ? "100%" : 400,
        height: isMobile ? "60%" : "100%",
        position: "fixed",
        top: isMobile ? "auto" : "64px",
        paddingTop: "64px",
        padding: 2,
        boxShadow: "0px -2px 10px rgba(0, 0, 0, 0.2)",
        display: "flex",
        flexDirection: "column",
        borderRadius: isMobile ? "20px 20px 0 0 !important" : "0", // Forzamos el estilo
        overflow: "hidden",

    },
  }}
>
  {isMobile && (
    <Box sx={{ display: 'flex', justifyContent: 'center', my: 1 }}>
      <Box
        sx={{
          width: 40,
          height: 4,
          backgroundColor: '#ccc',
          borderRadius: 2,
        }}
      />
    </Box>
  )}

  {/* Contenido del carrito */}
  <Box sx={{ flex: 1, overflowY: "auto", padding: 2, maxHeight: 'calc(100vh - 290px)' }}>
    <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", mb: 2 }}>
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <ShoppingCart sx={{ mr: 1 }} />
        <Typography variant="h6">Carro de compras</Typography>
      </Box>
      <Typography variant="body1">
        {totalQuantity} {totalQuantity === 1 ? "producto" : "productos"}
      </Typography>
    </Box>

    <Box sx={{ mb: 2 }}>
      <Typography
        variant="body2"
        color="error"
        sx={{ cursor: "pointer" }}
        onClick={handleClearCart}
      >
        Vaciar carrito
      </Typography>
    </Box>

    <List>
      {cartItems.map((item) => (
        <ListItem key={item.id}>
          <Avatar
            src={item.image}
            alt={item.name}
            sx={{ width: 56, height: 56, marginRight: 2 }}
          />
          <ListItemText
            primary={
              <>
                <Typography variant="body2" component="span" color="textSecondary" sx={{ fontSize: '0.8rem', color: 'gray' }}>
                  {item.brand}
                </Typography>
                <br />
                <Typography variant="body1" component="span" sx={{ fontWeight: 'bold' }}>
                  {item.name}
                </Typography>
              </>
            }
            secondary={
              <>
                <Typography component="span">{`${formatPrice(item.price)} x ${item.quantity}`}</Typography>
                <br />
                {item.isMembership ? (
                  <Typography component="span" variant="body2" sx={{ color: 'green', fontSize: '0.8rem' }}>
                    Precio de membresía
                  </Typography>
                ) : (
                  <Typography component="span" variant="body2" sx={{ color: 'red', fontSize: '0.8rem' }}>
                    Precio normal
                  </Typography>
                )}
              </>
            }
          />

          <ListItemSecondaryAction>
            <IconButton
              edge="end"
              onClick={() => handleDecrease(item)}
              disabled={item.quantity <= 1}
            >
              <Remove />
            </IconButton>
            <IconButton edge="end" onClick={() => handleIncrease(item)}>
              <Add />
            </IconButton>
            <IconButton edge="end" onClick={() => removeProductAndUpdateBalance(item)}>
              <Delete />
            </IconButton>
          </ListItemSecondaryAction>
        </ListItem>
      ))}
    </List>
  </Box>

  {/* Contenedor de botones y subtotal */}
  <Box sx={{ padding: 2, borderTop: "1px solid #e0e0e0", flexShrink: 0 }}>
    <Typography variant="h6" sx={{ mb: 2 }}>
      Subtotal: {formatPrice(totalPrice())}
    </Typography>
    <Typography variant="body2" sx={{ color: 'green', mb: 2 }}>
      Ahorro total: {formatPrice(calculateTotalSavings())}
    </Typography>
    <Button
      variant="contained"
      color="success"
      fullWidth
      sx={{ mb: 2 }}
      onClick={handleGoToCart}
    >
      Ir a pagar
    </Button>
    <Button variant="outlined" color="success" fullWidth onClick={onClose}>
      Seguir comprando
    </Button>
  </Box>

  <Dialog open={openDialog} onClose={handleDialogClose}>
    <DialogTitle>Saldo de membresía insuficiente</DialogTitle>
    <DialogContent>
      <DialogContentText>
        Has alcanzado el límite de tu saldo de membresía. Los próximos productos se agregarán a precio normal.
      </DialogContentText>
    </DialogContent>
    <DialogActions>
      <Button onClick={handleDialogClose} color="primary">
        Entendido
      </Button>
    </DialogActions>
  </Dialog>
</SwipeableDrawer>



  );
};

export default CartDrawer;
