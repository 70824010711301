import React, { useState, useEffect, useRef, useContext } from "react";
import { Link } from "react-router-dom";
import {
  Button,
  Box,
  Typography,
  AppBar,
  Toolbar,
  useMediaQuery,
  IconButton,
  Drawer,
  List,
  ListItem,
  ListItemText,
  Divider,
  Fab,
} from "@mui/material";
import PersonIcon from "@mui/icons-material/Person";
import MenuIcon from "@mui/icons-material/Menu";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import MonetizationOnIcon from "@mui/icons-material/MonetizationOn";
import CloseIcon from "@mui/icons-material/Close";
import SavingsIcon from "@mui/icons-material/Savings";
import "./Header.scss";
import CartDrawer from "../CartPage/CartDrawer";
import facebook from "../../assets/icon/facebook.svg";
import instagram from "../../assets/icon/instagram.svg";
import youtube from "../../assets/icon/youtube.svg";
import logo from "../../assets/temp/logo-header-takay.png";
import UserProfileMenu from "../UserProfileMenu/UserProfileMenu";
import Auth from "../Auth/Auth";
import MembershipModal from "../MembershipModal/MembershipModal";
import { AuthContext } from "../../context/AuthContext";
import { CartContext } from "../../context/CartContext";
import logoMovil from "../../assets/temp/takay-movil.png";
import CartIcon from "../Cart/CartIcon";

export const Header = () => {
  const [showMenu, setShowMenu] = useState(false);
  const [openLoginModal, setOpenLoginModal] = useState(false);
  const [openLoginOptions, setOpenLoginOptions] = useState(false);
  const [openMembershipModal, setOpenMembershipModal] = useState(false);
  const [openCartDrawer, setOpenCartDrawer] = useState(false);
  const { user, logout, balance } = useContext(AuthContext);
  const { cartItems, totalSavings, totalPrice } = useContext(CartContext); // Added totalPrice here

  const isMobile = useMediaQuery("(max-width: 1024px)");
  const loginOptionsRef = useRef(null);

  const toggleMenu = () => {
    setShowMenu((prev) => !prev);
  };

  const handleOpenLoginModal = () => {
    setOpenLoginModal(true);
    setOpenLoginOptions(false);
  };

  const handleCloseLoginModal = () => {
    setOpenLoginModal(false);
  };

  const toggleLoginOptions = () => {
    setOpenLoginOptions((prev) => !prev);
  };

  const handleLogout = () => {
    logout();
  };

  const handleOpenMembershipModal = () => {
    setOpenMembershipModal(true);
  };

  const handleCloseMembershipModal = () => {
    setOpenMembershipModal(false);
  };

  const handleOpenCartDrawer = () => {
    setOpenCartDrawer(true);
  };

  const handleCloseCartDrawer = () => {
    setOpenCartDrawer(false);
  };

  useEffect(() => {
    function handleClickOutside(event) {
      if (
        loginOptionsRef.current &&
        !loginOptionsRef.current.contains(event.target)
      ) {
        setOpenLoginOptions(false);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [loginOptionsRef]);

  const hasNormalPriceItems = cartItems.some((item) => !item.isMembership);

  return (
    <>
      <AppBar
        position="sticky"
        sx={{ zIndex: (theme) => theme.zIndex.drawer + 1, backgroundColor: "#5fa801" }}
      >
        <Toolbar sx={{ justifyContent: "space-between" }}>
          <IconButton
            edge="start"
            color="inherit"
            aria-label="menu"
            onClick={toggleMenu}
            sx={{ display: { md: "none" } }}
          >
            <MenuIcon />
          </IconButton>

          <Link to="/" style={{ textDecoration: "none" }}>
            <img
              src={isMobile ? logoMovil : logo}
              alt="T'akay Logo"
              style={{ height: isMobile ? 40 : 60 }}
            />
          </Link>

          {!isMobile && user && (
            <Box sx={{ display: "flex", justifyContent: "center", mx: 3 }}>
              <Fab
                variant="extended"
                sx={{
                  backgroundColor: "orange",
                  color: "white",
                  "&:hover": {
                    backgroundColor: "#5fa801",
                  },
                }}
              >
                <MonetizationOnIcon sx={{ mr: 1 }} />
                <Typography variant="h6" sx={{ fontWeight: "bold", fontSize: "1.1rem" }}>
                  {`Saldo de membresía: $${balance.toLocaleString("es-CL")}`}
                </Typography>
              </Fab>
            </Box>
          )}

          {!isMobile && hasNormalPriceItems && (
            <Box sx={{ display: "flex", justifyContent: "center", mx: 3, gap: 2 }}>
              <Fab
                variant="extended"
                sx={{
                  backgroundColor: "#ff6666",
                  color: "white",
                  "&:hover": {
                    backgroundColor: "#ff3333",
                  },
                }}
              >
                <SavingsIcon sx={{ mr: 1 }} />
                <Typography variant="h6" sx={{ fontWeight: "bold", fontSize: "1.1rem" }}>
                  Ahorra: ${totalSavings.toLocaleString('es-CL')} con membresía
                </Typography>
              </Fab>
            </Box>
          )}

          {!isMobile && (
            <Box sx={{ display: "flex", alignItems: "center", gap: "20px" }}>
              <Link to="/" style={{ textDecoration: "none", color: "white" }}>
                <Typography variant="h6">Home</Typography>
              </Link>
              <a href="#como-funciona" style={{ textDecoration: "none", color: "white" }}>
                <Typography variant="h6">¿Cómo Funciona?</Typography>
              </a>
              <a href="#categorias" style={{ textDecoration: "none", color: "white" }}>
                <Typography variant="h6">Categorías</Typography>
              </a>
              <a
                href="#categorias"
                style={{ textDecoration: "none", color: "white" }}
                onClick={handleOpenMembershipModal}
              >
                <Typography variant="h6">Membresía</Typography>
              </a>
            </Box>
          )}

          <Box sx={{ display: "flex", alignItems: "center", ml: 3 }}>
            <div className="header__cart">
              <CartIcon className="header__cart-icon" onClick={handleOpenCartDrawer} />
              {isMobile && !user && (
              <IconButton
                color="inherit"
                onClick={handleOpenLoginModal}
                sx={{ ml: 1, marginTop: -1 }}
              >
                <PersonIcon />
              </IconButton>
            )}
            </div>

            {!isMobile && (
              <>
                {user ? (
                  <UserProfileMenu authenticatedUser={user} onLogout={logout} />
                ) : (
                  <Button
                    onClick={handleOpenLoginModal}
                    variant="contained"
                    style={{ backgroundColor: "orange", color: "white" }}
                    startIcon={<PersonIcon />}
                  >
                    INGRESAR
                  </Button>
                )}
              </>
            )}
          </Box>
        </Toolbar>
      </AppBar>

      {isMobile && (
        <Box
          sx={{
            backgroundColor: "orange",
            color: "white",
            textAlign: "center",
            p: 1,
            position: "fixed",
            bottom: 0,
            left: 0,
            width: "100%",
            zIndex: 1200,
          }}
        >
          <MonetizationOnIcon sx={{ mr: 1 }} />
          <Typography variant="h6" sx={{ fontWeight: "bold", fontSize: "1rem", display: "inline-block" }}>
            {balance > 0
              ? `Saldo de membresía: $${balance.toLocaleString("es-CL")}`
              : `Ahorra $${totalSavings.toLocaleString('es-CL')} con una membresía`}
          </Typography>
        </Box>
      )}

<Drawer
  anchor="left"
  open={showMenu}
  onClose={() => setShowMenu(false)}
  sx={{ ".MuiDrawer-paper": { width: isMobile ? "100%" : "250px" } }}
>
  <Box
    sx={{
      display: "flex",
      flexDirection: "column",
      p: 2,
      mt: "64px",
      height: "100%", // Para que el contenedor ocupe toda la altura
      justifyContent: "space-between", // Para colocar el botón de cerrar sesión al final
      position: "relative", // Necesario para posicionar la X
    }}
    role="presentation"
  >
    {/* Botón de Cerrar (X) en la parte superior derecha */}
    <IconButton
      onClick={() => setShowMenu(false)}
      color="inherit"
      sx={{
        position: "absolute",
        top: "10px",
        right: "10px",
      }}
    >
      <CloseIcon />
    </IconButton>

    <Box>
      <Box sx={{ display: "flex", alignItems: "center", mb: 2 }}>
        {user && (
          <Typography
            variant="h6"
            sx={{
              fontWeight: "bold",
              textAlign: "left",
              flexGrow: 1,
              backgroundColor: "rgba(0, 128, 0, 0.2)", // Fondo verde transparente
              borderRadius: "5px", // Bordes redondeados opcionales
              padding: "8px", // Espaciado interno para el texto
            }}
          >
            {`Hola, ${user}`}
          </Typography>
        )}
      </Box>

      <Link to="/" style={{ textDecoration: "none", marginBottom: 10 }}>
        <Typography variant="h6">Home</Typography>
      </Link>
      <Divider />
      <a href="#como-funciona" style={{ textDecoration: "none", marginBottom: 10 }}>
        <Typography variant="h6">¿Cómo Funciona?</Typography>
      </a>
      <Divider />
      <a href="#categorias" style={{ textDecoration: "none", marginBottom: 10 }}>
        <Typography variant="h6">Categorías</Typography>
      </a>
      <Divider />
      <a onClick={handleOpenMembershipModal} style={{ textDecoration: "none", marginBottom: 10 }}>
        <Typography variant="h6">Membresía</Typography>
      </a>
      <Divider />
    </Box>

    {/* Botón de Cerrar Sesión al final del Drawer */}
    {user && (
      <Box sx={{ textAlign: "center", mt: 2 }}>
        <Button
          variant="contained"
          color="error"
          onClick={handleLogout}
          startIcon={<PersonIcon />}
          sx={{ width: "100%" }}
        >
          Cerrar Sesión
        </Button>
      </Box>
    )}
  </Box>
</Drawer>



      <MembershipModal open={openMembershipModal} onClose={handleCloseMembershipModal} />

      <Auth open={openLoginModal} onClose={handleCloseLoginModal} />

      <CartDrawer open={openCartDrawer} onClose={handleCloseCartDrawer}>
        <List>
          {cartItems.length > 0 ? (
            cartItems.map((item) => (
              <ListItem key={item.id}>
                <ListItemText primary={item.name} secondary={`Cantidad: ${item.quantity}`} />
                <Typography>${parseFloat(item.price).toLocaleString("es-CL")}</Typography>
              </ListItem>
            ))
          ) : (
            <Typography variant="body2" sx={{ mt: 2 }}>
              Tu carrito está vacío.
            </Typography>
          )}
        </List>
        <Box sx={{ mt: 2 }}>
          <Typography variant="h6">Total: ${totalPrice().toLocaleString("es-CL")}</Typography>
        </Box>
      </CartDrawer>
    </>
  );
};

export default Header;
