import React from 'react';
import { Box, Card, CardContent, Typography, Grid } from '@mui/material';

export const Orders = ({ orders }) => {
  return (
    <Box sx={{ width: '100%', maxWidth: 800, mx: 'auto', p: 2 }}>
      <Typography variant="h4" align="center" sx={{ mb: 4 }}>
        Mis Compras
      </Typography>
      <Grid container spacing={2}>
        {orders.map((order, index) => (
          <Grid item xs={12} key={index}>
            <Card
              sx={{
                border: '1px solid #4caf50',
                boxShadow: '0 3px 5px rgba(0, 0, 0, 0.2)',
                '&:hover': {
                  boxShadow: '0 6px 10px rgba(0, 0, 0, 0.3)',
                },
              }}
            >
              <CardContent>
                <Typography variant="h6" color="textSecondary">
                  Fecha de compra: {order.created_at}
                </Typography>
                <Typography variant="body1">
                  Total pagado: ${order.final_amount.toLocaleString('es-ES')}
                </Typography>
                <Typography variant="body1">
                  Dirección de envío: {order.shipping_address}
                </Typography>
                <Typography variant="body1">
                  Productos comprados: {order.total_products}
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};
