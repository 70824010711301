import React, { useContext } from 'react';
import { CartContext } from '../../context/CartContext';
import { Badge } from '@mui/material';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import './CartIcon.scss'; 

const CartIcon = ({ className, onClick }) => {
  const { cartItems } = useContext(CartContext);

  const totalQuantity = cartItems.reduce((total, item) => total + item.quantity, 0);

  return (
    <Badge 
      badgeContent={totalQuantity} 
      color="primary" 
      className="cart-icon-badge"
      onClick={onClick} // Asignamos el onClick aquí
      sx={{ cursor: 'pointer' }} // Asegura que el cursor cambie a pointer para indicar que es clicable
    >
      <ShoppingCartIcon className={className} />
    </Badge>
  );
};

export default CartIcon;
