import React, { useState, useContext, useEffect } from "react";
import SwiperCore, { Navigation } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper-bundle.min.css";
import "swiper/swiper.min.css";
import "./TopSwiper.scss";
import { CartContext } from "../../context/CartContext";
import { AuthContext } from "../../context/AuthContext";
import {
  IconButton,
  TextField,
  Card,
  CardMedia,
  CardContent,
  Typography,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Box,
  useMediaQuery,
  useTheme
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import CloseIcon from "@mui/icons-material/Close";
import MembershipModal from "../MembershipModal/MembershipModal";
import { getProducts } from "../../client/apiProducts";

SwiperCore.use([Navigation]);

export const TopSwiper = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("xs"));
  const [subcategory, changeSubcategory] = useState("fruits");
  const { addToCart, removeFromCart, updateQuantity, cartItems, setCartItems } = useContext(CartContext);
  const { balance, user, setBalance } = useContext(AuthContext);
  const [products, setProducts] = useState([]);
  const [openDialog, setOpenDialog] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [openMembershipModal, setOpenMembershipModal] = useState(false);
  const [hasShownDialogOnce, setHasShownDialogOnce] = useState(false); // Estado para mostrar el diálogo solo una vez

  // Fetch products when component mounts
  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const response = [];//await getProducts();
        const fetchedProducts = response.map((item) => ({
          id: item.id,
          name: item.name || "Fruta Desconocida",
          price: parseInt(item.price), // Guardar el precio sin puntos decimales
          price_membership: parseInt(item.price_membership), // Guardar el precio de membresía sin puntos decimales
          image: item.url_img,
          brand: item.brand.name,
        }));
        setProducts(fetchedProducts);
      } catch (error) {
        console.error("Error fetching products:", error);
      }
    };

    fetchProducts();
  }, []);

  // Save cart items to local storage whenever they change
  useEffect(() => {
    if (cartItems.length > 0) {
      localStorage.setItem("cartItems", JSON.stringify(cartItems));
    }
  }, [cartItems]);

  const handleAddToCart = (product, isMembership = true) => {
    console.log(product);
    const productPrice = product.price;
    const membershipPrice = product.price_membership;

    // Verificar si el saldo es suficiente para agregar a precio de membresía
    if (isMembership && balance < membershipPrice) {
      if (!hasShownDialogOnce) {
        // Mostrar el diálogo solo una vez
        setSelectedProduct(product);
        setOpenDialog(true);
        setHasShownDialogOnce(true); // Marcar que ya se ha mostrado el diálogo una vez
        return; // No agregar el producto aún
      } else {
        // Si ya se mostró el diálogo una vez, agregar automáticamente con el precio normal
        isMembership = false;
      }
    }

    const priceToUse = isMembership ? membershipPrice : productPrice;

    // Agregar el producto al carrito (incluyendo ambos precios)
    const productToAdd = {
      ...product,
      price: priceToUse,
      price_membership: membershipPrice, // Siempre almacenar el precio de membresía
      price_normal: productPrice, // Almacenar el precio normal también
      isMembership: isMembership,
      brand: product.brand,
      id: isMembership ? String(product.id) : String(product.id) + "_normal", // Aseguramos IDs diferentes
    };

    addToCart(productToAdd, 1);

    // Descontar el saldo si es membresía y tiene saldo suficiente
    if (isMembership) {
      setBalance((prevBalance) => prevBalance - membershipPrice);
    }
  };

  const handleIncrease = (product) => {
    const membershipPrice = product.price_membership;
    const productPrice = product.price;

    const productMembershipId = String(product.id); // ID para el producto a precio de membresía
    const productNormalId = String(product.id) + "_normal"; // ID para el producto a precio normal

    const productInCart = cartItems.find((item) => item.id === productMembershipId && item.isMembership);
    const normalProductInCart = cartItems.find((item) => item.id === productNormalId && !item.isMembership);

    if (balance >= membershipPrice) {
      if (productInCart) {
        updateQuantity(productMembershipId, productInCart.quantity + 1);
      } else {
        handleAddToCart(product, true); // Agregar con precio de membresía
      }
      setBalance((prevBalance) => prevBalance - membershipPrice);
    } else {
      if (!hasShownDialogOnce) {
        setSelectedProduct(product); // Guardamos el producto que se intentó agregar
        setOpenDialog(true); // Abrir el diálogo
        setHasShownDialogOnce(true); // Marcar que ya se mostró el diálogo una vez
      } else {
        if (normalProductInCart) {
          updateQuantity(productNormalId, normalProductInCart.quantity + 1);
        } else {
          handleAddToCart(product, false); // Agregar el producto con precio normal
        }
      }
    }
  };

  const handleDecrease = (product) => {
    const membershipProductId = String(product.id);
    const normalProductId = String(product.id) + "_normal";

    let productInCart = cartItems.find((item) => item.id === normalProductId && !item.isMembership);

    if (!productInCart) {
      productInCart = cartItems.find((item) => item.id === membershipProductId && item.isMembership);
    }

    if (productInCart) {
      if (productInCart.quantity > 1) {
        updateQuantity(productInCart.id, productInCart.quantity - 1);
        if (productInCart.isMembership) {
          setBalance((prevBalance) => prevBalance + product.price_membership);
        }
      } else {
        removeFromCart(productInCart.id);
        if (productInCart.isMembership) {
          setBalance((prevBalance) => prevBalance + product.price_membership);
        }
      }
    }
  };

  const getProductQuantity = (productId) => {
    const productNormal = cartItems.find((item) => item.id === String(productId) + "_normal");
    const productMembership = cartItems.find((item) => item.id === String(productId) && item.isMembership);
    return {
      normal: productNormal ? productNormal.quantity : 0,
      membership: productMembership ? productMembership.quantity : 0,
      total: (productNormal ? productNormal.quantity : 0) + (productMembership ? productMembership.quantity : 0),
    };
  };

  const handleDialogClose = () => {
    setOpenDialog(false);
    setSelectedProduct(null);
  };

  const handleAddAtNormalPrice = () => {
    if (selectedProduct) {
      handleAddToCart(selectedProduct, false); // Agregar con precio normal
      handleDialogClose();
    }
  };

  const handleOpenMembershipModal = () => {
    setOpenMembershipModal(true);
    handleDialogClose();
  };

  const handleCloseMembershipModal = () => {
    setOpenMembershipModal(false);
  };

  return (
    <section className="section section--products-top">
      <p className="products-top__title">Productos TOP</p>
      <ul className="products-top__subcategories">
        {/* Botones para cambiar subcategorías */}
      </ul>

      {subcategory === "fruits" && (
        <Swiper
          navigation={true}
          loop={false}
          centeredSlides={false}
          slidesPerView={1}
          slidesPerGroup={5}
          spaceBetween={10}
          className="swiper-top-products"
          allowTouchMove={false}
          noSwiping={true}
          breakpoints={{
            640: { slidesPerView: 2, spaceBetween: 15, slidesPerGroup: 2 },
            768: { slidesPerView: 3, spaceBetween: 20, slidesPerGroup: 3 },
            1024: { slidesPerView: 5, spaceBetween: 20, slidesPerGroup: 5 },
          }}
        >
          {products.map((product) => {
            const { normal: quantityNormal, membership: quantityMembership, total: totalQuantity } =
              getProductQuantity(product.id);

            return (
              <SwiperSlide key={product.id}>
                <Card className="products-top__card" sx={{ maxWidth: 200 }}>
                  <CardMedia
                    component="img"
                    height="133"
                    image={product.image}
                    alt={product.name}
                    sx={{ objectFit: "cover" }}
                  />
                  <CardContent>
                    <Typography variant="caption" color="textSecondary">
                      {product.brand}
                    </Typography>
                    <Typography variant="body2" fontSize="0.75rem" color="textPrimary" fontWeight="bold" mt={1}>
                      {product.name}
                    </Typography>
                    <Typography variant="body1" fontSize="1.1rem" color="green" fontWeight="bold" mt={1}>
                      ${product.price_membership.toLocaleString("es-CL")}{" "}
                      <span style={{ fontSize: "0.8rem", color: "gray" }}>Membresía</span>
                    </Typography>
                    <Typography variant="body1" fontSize="1rem" color="textPrimary" mt={1}>
                      ${product.price.toLocaleString("es-CL")}{" "}
                      <Typography component="span" fontSize="0.8rem" color="textSecondary">
                        precio normal
                      </Typography>
                    </Typography>

                    {quantityMembership > 0 && (
                      <Typography variant="body2" color="green">
                        Membresía: {quantityMembership}
                      </Typography>
                    )}
                    {quantityNormal > 0 && (
                      <Typography variant="body2" color="red">
                        Normal: {quantityNormal}
                      </Typography>
                    )}

                    {totalQuantity === 0 ? (
                      <Button
                        onClick={() => handleAddToCart(product)}
                        variant="contained"
                        color="success"
                        fullWidth
                        sx={{ mt: 1 }}
                      >
                        Agregar
                      </Button>
                    ) : (
                      <div
                        className="quantity-selector"
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                          marginTop: "10px",
                        }}
                      >
                        <IconButton
                          onClick={() => handleDecrease(product)}
                          sx={{
                            color: "white",
                            backgroundColor: "green",
                            "&:hover": {
                              backgroundColor: "#66bb6a", // Verde más claro en hover
                            },
                          }}
                        >
                          <RemoveIcon />
                        </IconButton>
                        <TextField
                          value={totalQuantity}
                          variant="outlined"
                          size="small"
                          sx={{ width: "50px", textAlign: "center", backgroundColor: "#f5f5f5" }}
                          inputProps={{ readOnly: true }}
                        />
                        <IconButton
                          onClick={() => handleIncrease(product)}
                          sx={{
                            color: "white",
                            backgroundColor: "green",
                            "&:hover": {
                              backgroundColor: "#66bb6a", // Verde más claro en hover
                            },
                          }}
                        >
                          <AddIcon />
                        </IconButton>
                      </div>
                    )}
                  </CardContent>
                </Card>
              </SwiperSlide>
            );
          })}
        </Swiper>
      )}

      <Dialog
        open={openDialog}
        onClose={handleDialogClose}
        fullWidth={true}
        maxWidth="sm"
        fullScreen={isMobile} // Pantalla completa en móviles
        PaperProps={{
          sx: {
            margin: isMobile ? "10px" : "32px", // Márgenes pequeños en móviles
            padding: "16px",
            borderRadius: "12px", // Bordes redondeados
            boxShadow: "0 2px 10px rgba(0, 0, 0, 0.2)", // Sombra
          },
        }}
      >
        <Box display="flex" justifyContent="space-between" alignItems="center" sx={{ padding: "8px 16px" }}>
          <DialogTitle sx={{ flexGrow: 1, fontSize: "1rem" }}>Saldo de membresía insuficiente</DialogTitle>
          <IconButton onClick={handleDialogClose} sx={{ color: "gray" }}>
            <CloseIcon />
          </IconButton>
        </Box>
        <DialogContent>
          <DialogContentText sx={{ fontSize: "1rem", textAlign: "center" }}>
            Has alcanzado el límite de tu saldo de membresía. Obtén tu membresía y sigue disfrutando de grandes descuentos.
          </DialogContentText>
        </DialogContent>
        <DialogActions
          sx={{
            justifyContent: "center",
            flexDirection: "column",
            gap: "8px",
            padding: "16px",
          }}
        >
          <Button
            onClick={handleAddAtNormalPrice}
            sx={{
              color: "green",
              borderColor: "green",
              backgroundColor: "white",
              width: "100%",
            }}
            variant="outlined"
          >
            Comprar a precio normal
          </Button>
          <Button
            onClick={handleOpenMembershipModal}
            sx={{
              width: "100%",
              color: "white",
              backgroundColor: "green",
            }}
            variant="contained"
          >
            Obtener Membresía
          </Button>
        </DialogActions>
      </Dialog>

      <MembershipModal open={openMembershipModal} onClose={handleCloseMembershipModal} />
    </section>
  );
};
