import React, { useState, useEffect, useContext } from 'react';
import { Typography, Card, CardContent, Box, Button, CircularProgress, Alert } from '@mui/material';
import { createSalesWithTransfer } from '../../client/apiSalesOrder';
import { CartContext } from "../../context/CartContext";

export const TransferPaymentInfo = () => {
  const [loading, setLoading] = useState(true); // Estado de carga
  const [error, setError] = useState(null); // Estado de error
  const [successMessage, setSuccessMessage] = useState(''); // Mensaje de éxito
  const { clearCart } = useContext(CartContext);
  useEffect(() => {
    // Lógica para llamar al servicio de la API cuando se monta el componente
    const initiateTransfer = async () => {
      try {
        const sales = localStorage.getItem("cartData");
        const response = await createSalesWithTransfer(sales); // Llamada a la API

        if (response?.success) {
          setSuccessMessage(response.message); // Si la respuesta es exitosa, muestra el mensaje

          // Elimina el carrito del localStorage
          localStorage.removeItem("cartData");
          localStorage.removeItem("cartItems");
          clearCart();
        } else {
          throw new Error(response.message || 'Error en la confirmación del pedido');
        }
      } catch (error) {
        setError(error.message || 'Hubo un problema al confirmar tu pedido');
      } finally {
        setLoading(false); // Finaliza la carga
      }
    };

    initiateTransfer();
  }, []);

  const handleContact = () => {
    // Lógica para contactar por WhatsApp o enviar un correo
    window.open('https://wa.me/973765050', '_blank'); // Reemplazar con el número de WhatsApp correspondiente
  };

  if (loading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return (
      <Box sx={{ maxWidth: 600, margin: 'auto', mt: 4 }}>
        <Alert severity="error">{error}</Alert>
      </Box>
    );
  }

  if (successMessage) {
    return (
      <Card sx={{ maxWidth: 600, margin: 'auto', mt: 4 }}>
        <CardContent>
          <Typography variant="h5" gutterBottom>
            {successMessage} {/* Mostrar mensaje personalizado de éxito */}
          </Typography>
          <Typography variant="body1" gutterBottom>
            Para completar tu pedido, realiza una transferencia bancaria a la siguiente cuenta:
          </Typography>
          <Box my={2}>
            <Typography variant="body2"><strong>Banco:</strong> BCI</Typography>
            <Typography variant="body2"><strong>Tipo de cuenta:</strong> Cuenta Corriente/Vista</Typography>
            <Typography variant="body2"><strong>Número de cuenta:</strong> 85132390</Typography>
            <Typography variant="body2"><strong>RUT:</strong> 17.533.302-9</Typography>
            <Typography variant="body2"><strong>Titular:</strong> María José Márquez López</Typography>
            <Typography variant="body2"><strong>Correo:</strong> facturacion@takayapp.cl</Typography>
          </Box>
          <Typography variant="body1" gutterBottom>
            Por favor, envíanos el comprobante de la transferencia a través de WhatsApp o envíalo por correo a la dirección indicada. Una vez recibido el comprobante, procederemos con la preparación de tu pedido.
          </Typography>
          <Box display="flex" justifyContent="center" mt={3}>
            <Button variant="contained" color="success" onClick={handleContact}>
              Contactar por WhatsApp
            </Button>
          </Box>
        </CardContent>
      </Card>
    );
  }

  return null; // Si no hay ni error ni éxito, no muestra nada (esto solo debe ocurrir mientras carga)
};

export default TransferPaymentInfo;
