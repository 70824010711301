import React, { useState, useContext } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  Typography,
  Box,
  CircularProgress,
} from "@mui/material";
import { loginApi } from "../../client/apiAuth"; // Ajusta la ruta según tu proyecto
import { jwtDecode } from "jwt-decode"; // Asegúrate de importar correctamente jwtDecode
import { AuthContext } from "../../context/AuthContext";

const Auth = ({ open, onClose }) => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [errorDialogOpen, setErrorDialogOpen] = useState(false);
  const { login } = useContext(AuthContext);
  

  const handleLogin = async () => {
    if (!username || !password) {
      setError("Por favor, ingrese ambos campos.");
      setErrorDialogOpen(true);
      return;
    }

    setLoading(true);
    try {
      const response = await loginApi(username, password);
      const token = response.token;

      if (token) {
        const decodedToken = jwtDecode(token);

        localStorage.setItem("token", token);
        localStorage.setItem("user", JSON.stringify(decodedToken));
        login({ username: decodedToken.username, token });

        onClose();
      } else {
        throw new Error("Token not received");
      }
    } catch (error) {
      console.error("Login failed:", error);
      setError("Usuario o contraseña incorrectos.");
      setErrorDialogOpen(true);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Dialog open={open} onClose={onClose}>
        <DialogTitle>
          <Typography fontWeight="bold">Ingresa a tu cuenta T'akay</Typography>
        </DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            id="email"
            label="Correo Electrónico"
            type="email"
            fullWidth
            variant="outlined"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            sx={{
              "& .MuiOutlinedInput-root": {
                "& fieldset": { borderColor: "orange" },
                "&:hover fieldset": { borderColor: "orange" },
                "&.Mui-focused fieldset": { borderColor: "orange" },
              },
            }}
          />
          <TextField
            margin="dense"
            id="password"
            label="Contraseña"
            type="password"
            fullWidth
            variant="outlined"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            sx={{
              "& .MuiOutlinedInput-root": {
                "& fieldset": { borderColor: "orange" },
                "&:hover fieldset": { borderColor: "orange" },
                "&.Mui-focused fieldset": { borderColor: "orange" },
              },
            }}
          />
          <Typography
            component="a"
            href="/recuperar-password"
            sx={{ display: "block", mt: 2, color: "#1976d2" }}
          >
            ¿Olvidaste tu contraseña?
          </Typography>
        </DialogContent>
        <DialogActions sx={{ justifyContent: "center" }}>
          <Button
            onClick={onClose}
            variant="outlined"
            sx={{ color: "green", borderColor: "green" }}
          >
            CANCELAR
          </Button>
          <Button
            onClick={handleLogin}
            variant="contained"
            sx={{ backgroundColor: "green", color: "white" }}
            disabled={loading}
          >
            {loading ? <CircularProgress size={24} color="inherit" /> : "INICIAR SESIÓN"}
          </Button>
        </DialogActions>
        <Box sx={{ textAlign: "center", mb: 2 }}>
          <Typography variant="body2">
            ¿No tienes cuenta?{" "}
            <a href="/register" style={{ color: "#1976d2", textDecoration: "none" }}>
              Regístrate
            </a>
          </Typography>
        </Box>
      </Dialog>

      {/* Dialog para mensajes de error */}
      <Dialog open={errorDialogOpen} onClose={() => setErrorDialogOpen(false)}>
        <DialogTitle>Error</DialogTitle>
        <DialogContent>
          <Typography variant="body1">{error}</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setErrorDialogOpen(false)} color="primary">
            Aceptar
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default Auth;
