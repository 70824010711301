import axios from 'axios';
import { API_URL } from '../const/api';

const API = `${API_URL}/clients`; // Cambia la URL según tu configuración
export const registerClient = async (userData) => {
  try {
    console.log(userData)
    userData.rut = userData.rut.replace('.','');
    const response = await axios.post(API, userData, {
      headers: {
        'Content-Type': 'application/json',
      },
    });
    return response.data;
  } catch (error) {
    console.error('Error en la solicitud:', error);
    throw error;
  }
};


export const getClientById = async (id) => {
  try {

    const response = await axios.get(API+'/'+id, {
      headers: {
        'Content-Type': 'application/json',
      },
    });
    return response.data[0];
  } catch (error) {
    console.error('Error en la solicitud:', error);
    throw error;
  }
};

export const updateClientData = async (updatedData) => {
  try {

    const response = await axios.patch(API, updatedData,  {
      headers: {
        'Content-Type': 'application/json',
      },
    });
    return response.data;
  } catch (error) {
    console.error('Error en la solicitud:', error);
    throw error;
  }
};