import React from 'react';
import { Box, Typography, Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import useMediaQuery from '@mui/material/useMediaQuery';

const EmptyCart = () => {
  const navigate = useNavigate();
  const isMobile = useMediaQuery("(max-width:600px)");

  const handleGoBack = () => {
    navigate('/');
  };

  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      textAlign="center"
      height="100%"
      padding={isMobile ? 2 : 5}
    >
      <ShoppingCartIcon sx={{ fontSize: isMobile ? 60 : 100, color: 'gray' }} />
      <Typography variant={isMobile ? "h5" : "h3"} mt={2} mb={2}>
        Tu carrito está vacío
      </Typography>
      <Typography variant="body1" mb={3}>
        Parece que aún no has agregado productos a tu carrito.
      </Typography>
      <Button variant="contained" color="primary" onClick={handleGoBack}>
        Ir a la tienda
      </Button>
    </Box>
  );
};

export default EmptyCart;
