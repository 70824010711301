import React, { useState, useEffect, useContext } from 'react';
import { Box, Button, Grid, TextField, Typography, Divider } from '@mui/material';
import { AccountCircle, ListAlt, LocationOn, MonetizationOn } from '@mui/icons-material';
import { green } from '@mui/material/colors';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import { getClientById, updateClientData } from '../../client/apiClients'; 
import { getSalesOrderClientById } from '../../client/apiSalesOrder';
import { getMembershipsByClient } from '../../client/apiMemberships';
import { getDirectionsByClient } from '../../client/apiDirections';
import { AuthContext } from '../../context/AuthContext';
import { Orders } from "../Account/Orders";
import MembershipHistory from "../Account/MembershipHistory";
import { Addresses } from "../Account/Directions";

export const Account = () => {
  const { userId } = useContext(AuthContext);
  const [orders, setOrders] = useState([]);
  const [memberships, setMemberships] = useState([]);
  const [directions, setDirections] = useState([]);
  const [selectedTab, setSelectedTab] = useState('personalData');
  const [clientData, setClientData] = useState({
    name: '',
    last_name: '',
    rut: '',
    email: '',
    phone: '',
    date_of_birthday: null,
  });

  useEffect(() => {
    const fetchClientData = async () => {
      try {
        const data = await getClientById(userId);
        const salesOrders = await getSalesOrderClientById(userId);
        const membershipsHistory = await getMembershipsByClient(userId);
        const directionsClients = await getDirectionsByClient(userId);
        console.log(data);
        setOrders(salesOrders);
        setMemberships(membershipsHistory);
        setDirections(directionsClients);

        const birthday = data.date_of_birthday ? dayjs(data.date_of_birthday) : null;

        setClientData({
          name: data.name || '',
          last_name: data.last_name || '',
          rut: data.rut || '',
          email: data.email || '',
          phone: data.phone || '',
          date_of_birthday: birthday,
        });
      } catch (error) {
        console.error('Error al obtener los datos del cliente:', error);
      }
    };

    if (userId) {
      fetchClientData();
    }
  }, [userId]);

  const handleTabChange = (tab) => {
    setSelectedTab(tab);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setClientData({
      ...clientData,
      [name]: value,
    });
  };

  const handleUpdate = async () => {
    // Crear un objeto con los datos a actualizar, excluyendo el RUT
    const { rut, ...updatedData } = {
      ...clientData,
      date_of_birthday: clientData.date_of_birthday ? clientData.date_of_birthday.format('YYYY-MM-DD') : null, 
      userId, 
    };

    try {
      await updateClientData(updatedData); 
      alert('Perfil actualizado con éxito');
    } catch (error) {
      console.error('Error al actualizar el perfil:', error);
      alert('Error al actualizar el perfil');
    }
  };

  const renderContent = () => {
    switch (selectedTab) {
      case 'personalData':
        return (
          <Box
            sx={{
              width: '100%',
              maxWidth: 800,
              mx: 'auto',
              p: 2,
              bgcolor: 'background.paper',
              borderRadius: 1,
              boxShadow: 1,
            }}
          >
            <Typography variant="h6" align="left" sx={{ mb: 2 }}>
              Mis Datos
            </Typography>
            <Grid container spacing={2} justifyContent="center">
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  label="RUT"
                  variant="outlined"
                  name="rut"
                  value={clientData.rut}
                  InputProps={{
                    readOnly: true, // Bloquear la edición del RUT
                  }}
                  disabled
                  sx={{
                    '& .MuiOutlinedInput-root': {
                      '& fieldset': {
                        borderColor: green[500],
                      },
                      '&:hover fieldset': {
                        borderColor: green[700],
                      },
                      '&.Mui-focused fieldset': {
                        borderColor: green[500],
                      },
                    },
                    '& .MuiInputBase-root': {
                      width: '100%',
                    },
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  label="Nombre"
                  variant="outlined"
                  name="name"
                  value={clientData.name}
                  onChange={handleChange}
                  sx={{
                    '& .MuiOutlinedInput-root': {
                      '& fieldset': {
                        borderColor: green[500],
                      },
                      '&:hover fieldset': {
                        borderColor: green[700],
                      },
                      '&.Mui-focused fieldset': {
                        borderColor: green[500],
                      },
                    },
                    '& .MuiInputBase-root': {
                      width: '100%',
                    },
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  label="Apellido"
                  variant="outlined"
                  name="last_name"
                  value={clientData.last_name}
                  onChange={handleChange}
                  sx={{
                    '& .MuiOutlinedInput-root': {
                      '& fieldset': {
                        borderColor: green[500],
                      },
                      '&:hover fieldset': {
                        borderColor: green[700],
                      },
                      '&.Mui-focused fieldset': {
                        borderColor: green[500],
                      },
                    },
                    '& .MuiInputBase-root': {
                      width: '100%',
                    },
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  label="Email"
                  variant="outlined"
                  name="email"
                  value={clientData.email}
                  onChange={handleChange}
                  sx={{
                    '& .MuiOutlinedInput-root': {
                      '& fieldset': {
                        borderColor: green[500],
                      },
                      '&:hover fieldset': {
                        borderColor: green[700],
                      },
                      '&.Mui-focused fieldset': {
                        borderColor: green[500],
                      },
                    },
                    '& .MuiInputBase-root': {
                      width: '100%',
                    },
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  label="Teléfono"
                  variant="outlined"
                  name="phone"
                  value={clientData.phone}
                  onChange={handleChange}
                  sx={{
                    '& .MuiOutlinedInput-root': {
                      '& fieldset': {
                        borderColor: green[500],
                      },
                      '&:hover fieldset': {
                        borderColor: green[700],
                      },
                      '&.Mui-focused fieldset': {
                        borderColor: green[500],
                      },
                    },
                    '& .MuiInputBase-root': {
                      width: '100%',
                    },
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    label="Fecha de Nacimiento"
                    value={clientData.date_of_birthday}
                    onChange={(newValue) => setClientData({ ...clientData, date_of_birthday: newValue })}
                    renderInput={(params) => (
                      <TextField
                        fullWidth
                        variant="outlined"
                        {...params}
                        sx={{
                          '& .MuiOutlinedInput-root': {
                            '& fieldset': {
                              borderColor: green[500],
                            },
                            '&:hover fieldset': {
                              borderColor: green[700],
                            },
                            '&.Mui-focused fieldset': {
                              borderColor: green[500],
                            },
                          },
                          '& .MuiInputBase-root': {
                            width: '100%', 
                          },
                        }}
                      />
                    )}
                  />
                </LocalizationProvider>
              </Grid>
            </Grid>
            <Box display="flex" justifyContent="center" gap={2} mt={4}>
              <Button variant="contained" sx={{ backgroundColor: 'green', color: 'white' }} onClick={handleUpdate}>
                Guardar cambios
              </Button>
              <Button variant="outlined" sx={{ borderColor: 'green', color: 'green' }}>
                Cambiar contraseña
              </Button>
            </Box>
          </Box>
        );
      case 'orders':
        return <Orders orders={orders} />;
      case 'addresses':
        return <Addresses addresses={directions} />;
      case 'membershipBalance':
        return <MembershipHistory memberships={memberships} />;
      default:
        return null;
    }
  };

  return (
    <Box display="flex" flexDirection="column" alignItems="center" p={2}>
      <Box display="flex" justifyContent="center" mb={2}>
        <Button
          variant={selectedTab === 'personalData' ? 'contained' : 'outlined'}
          onClick={() => handleTabChange('personalData')}
          startIcon={<AccountCircle />}
          sx={{
            mx: 1,
            backgroundColor: selectedTab === 'personalData' ? 'green' : 'transparent',
            color: selectedTab === 'personalData' ? 'white' : 'green',
            borderColor: 'green',
            '&:hover': {
              backgroundColor: selectedTab === 'personalData' ? 'darkgreen' : 'rgba(0, 128, 0, 0.1)',
            },
          }}
        >
          Datos personales
        </Button>

        <Button
          variant={selectedTab === 'orders' ? 'contained' : 'outlined'}
          onClick={() => handleTabChange('orders')}
          startIcon={<ListAlt />}
          sx={{
            mx: 1,
            backgroundColor: selectedTab === 'orders' ? 'green' : 'transparent',
            color: selectedTab === 'orders' ? 'white' : 'green',
            borderColor: 'green',
            '&:hover': {
              backgroundColor: selectedTab === 'orders' ? 'darkgreen' : 'rgba(0, 128, 0, 0.1)',
            },
          }}
        >
          Pedidos
        </Button>

        <Button
          variant={selectedTab === 'addresses' ? 'contained' : 'outlined'}
          onClick={() => handleTabChange('addresses')}
          startIcon={<LocationOn />}
          sx={{
            mx: 1,
            backgroundColor: selectedTab === 'addresses' ? 'green' : 'transparent',
            color: selectedTab === 'addresses' ? 'white' : 'green',
            borderColor: 'green',
            '&:hover': {
              backgroundColor: selectedTab === 'addresses' ? 'darkgreen' : 'rgba(0, 128, 0, 0.1)',
            },
          }}
        >
          Direcciones
        </Button>

        <Button
          variant={selectedTab === 'membershipBalance' ? 'contained' : 'outlined'}
          onClick={() => handleTabChange('membershipBalance')}
          startIcon={<MonetizationOn />}
          sx={{
            mx: 1,
            backgroundColor: selectedTab === 'membershipBalance' ? 'green' : 'transparent',
            color: selectedTab === 'membershipBalance' ? 'white' : 'green',
            borderColor: 'green',
            '&:hover': {
              backgroundColor: selectedTab === 'membershipBalance' ? 'darkgreen' : 'rgba(0, 128, 0, 0.1)',
            },
          }}
        >
          Saldo membresía
        </Button>
      </Box>

      <Divider sx={{ width: '100%', mb: 2 }} />

      {renderContent()}
    </Box>
  );
};
